@import "../../../colors.scss";

.driver-trip-details-container {
  border-top: 1px solid $gray030;
  padding-top: 10px;
  height: 462px;

  .segment-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.drop-off {
      margin-top: 26px;
    }

    .segment-wrapper-side {
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
    }

    .address-segment-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      margin-left: 6px;
      color: $darkLandline;
    }

    .trip-status {
      height: 100%;
      font-size: 10px;
      font-weight: 600;
      line-height: 15px;
      color: $green;
      padding: 5px 0px 0px 4px;
    }

    .trip-time {
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      color: $green;
      margin-right: 5px;
    }

    .trip-time-box {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 21px;
      height: 19px;
      font-size: 10px;
      font-weight: 600;
      background-color: $green;
      color: $white;
      border-radius: 3px;
      padding: 0px 3px;

      &.red {
        background-color: $red;
      }
    }
  }

  .passenger-details {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .number-of-passengers-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .number-of-passengers-text {
      color: $gray070;
      font-size: 10px;
    }

    .number-of-passengers {
      display: flex;
      width: 23px;
      height: 24px;
      background-color: $green;
      color: $white;
      border-radius: 3px;
      font-size: 10px;
      justify-content: center;
      align-items: center;
      margin-left: 3px;
    }
  }

  .input-field-pair {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
  }
}
