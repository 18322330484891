@import "../../../colors.scss";

.input-field-container {
  margin-top: 10px;
  max-width: 750px;
  min-width: 100px;
  width: 100%;

  @font-face {
    font-family: "password";
    font-style: normal;
    font-weight: 400;
    src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
  }

  .input-field-label-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .input-field-label {
    margin-bottom: 8px;
    color: $gray070;
    font-size: 12px;
    margin-right: 8px;

    &.secondary {
      font-size: 10px;
    }

    &.error {
      color: $red;
    }
  }

  .input-error {
    position: absolute;
    left: 0;
    bottom: -12px;
    font-size: 10px;
    z-index: 2;
    color: $red;
  }

  .input-field-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 8px;
    padding: 0px 12px;
    height: 32px;
    color: $darkLandline;

    &.primary {
      border: 1px solid $lightGray;
      background-color: $white;
    }

    &.secondary {
      height: 30px;
      background-color: $gray010;
    }

    &.error {
      border: 1px solid $red;
    }

    .icon-wrapper {
      height: fit-content;
      display: flex;

      &.clickable {
        cursor: pointer;
      }
    }

    .input-field {
      border: none;
      width: 100%;
      height: 100%;
      background-color: unset;
      font-size: 12px;
    }

    .input-preview {
      display: flex;
      border: none;
      width: 100%;
      height: 100%;
      font-size: 12px;
      align-items: center;
    }

    .input-field::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $gray050;
      opacity: 1; /* Firefox */
    }

    .input-field:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $gray050;
    }

    .input-field::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $gray050;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px $white inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .input-field:focus {
      outline: none;
    }

    .input-field:disabled {
      background-color: unset;
      color: $darkLandline;
    }
  }

  @media screen and (min-width: 768px) {
    .input-field-wrapper {
      height: 40px;
    }

    .input-field {
      font-size: 14px !important;
    }

    .input-field-label {
      font-size: 14px;
    }
  }
}
