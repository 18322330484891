@import "../../../colors.scss";

.driver-trip-row-container {
  padding: 0 10px 0px 15px;
  width: 100%;
  max-width: 700px;
  margin-bottom: 8px;
  box-shadow: 0px 0.84px 1.7px 0.84px rgba(66, 64, 62, 0.1);
  border-radius: 6.7px;
  max-height: 44px;
  overflow: hidden;

  &.animationRunning {
    transition: max-height 500ms;
  }

  &.expanded {
    max-height: 550px;
  }

  .trip-row-grid {
    height: 44px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns:
      minmax(45px, 1fr)
      minmax(80px, 1.5fr)
      minmax(63px, 1.2fr)
      minmax(34px, 0.5fr)
      minmax(33px, 0.5fr)
      22px;
  }

  .calendar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .item-wrapper {
    display: flex;
    align-items: center;

    &.expand-button {
      cursor: pointer;
    }
  }

  .icon-column-wrapper {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 2px;
  }

  .text-span {
    font-size: 12px;

    &.bold {
      font-weight: 600;
    }
  }

  .pnr-item {
    font-size: 12px;
    font-weight: 600;

    &.past {
      color: $gray050;
    }

    &.cancelled {
      color: $red;
    }
  }
}
