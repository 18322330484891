@import "../../../colors.scss";

.driver-trip-columns-container {
  margin-top: 21px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 700px;
  padding: 0 10px 0px 15px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns:
    minmax(45px, 1fr)
    minmax(80px, 1.5fr)
    minmax(63px, 1.2fr)
    minmax(34px, 0.5fr)
    minmax(33px, 0.5fr)
    22px;

  .driver-trip-column {
    font-size: 10px;
    color: $gray050;
  }
}
