@import "../../../colors.scss";

.customer-trip-row-container {
  width: 100%;
  max-width: 700px;
  box-shadow: 0px 0.841121px 1.68224px 0.841121px rgba(66, 64, 62, 0.1);
  border-radius: 7px;
  padding: 15px 13px;
  margin-bottom: 8px;
  max-height: 154px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &.animationRunning {
    transition: max-height 500ms;
  }

  &.expanded {
    height: fit-content;
    max-height: 640px;
  }

  .pnr-title {
    margin-left: 27px;
    font-size: 20px;
    font-weight: 700;

    &.past {
      color: $gray050;
    }

    &.cancelled {
      color: $red;
    }
  }

  .invisible-content {
    height: 1000px;
    width: 20px;
    position: absolute;
    top: 0;
    background-color: red;
  }
}
