@import "../../../colors.scss";

.vehicle-information-container {
  display: flex;
  margin-top: 50px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .vehicle-avatar-model-wrapper {
    display: flex;
    flex-direction: row;
  }

  .vehicle-model-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }

  .vehicle-model {
    font-size: 14px;
    font-weight: 700;
  }

  .vehicle-year {
    color: #e5e5e5;
    font-size: 14px;
    font-weight: 700;
  }

  .vehicle-color-wrapper {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
  }

  .color-box {
    margin-left: 10px;
    width: 15px;
    height: 15px;
    background-color: $darkLandline;
    border-radius: 2px;
  }
}
