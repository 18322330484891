@import "../../../colors.scss";

.loading-container {
  .loading-label {
    padding-left: 5px;
    color: $white;
  }

  .loading-image {
    vertical-align: -5px;
  }

  &.scroll {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-label {
      color: $darkLandline;
    }
  }

  &.expandable-loading,
  &.page-content-loading {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-label {
      color: $darkLandline;
    }
  }

  &.user-loading {
    .loading-label {
      visibility: hidden;
    }

    img {
      width: 37px;
      height: 37px;
    }
  }
}
