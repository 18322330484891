@import "../../../colors.scss";

.calendar-container {
  margin: 0px auto 0px auto;
  position: relative;
  width: 100%;
  max-width: 700px;

  .calendar-wrapper {
    width: 100%;
    max-width: 400px;
    position: absolute;
    top: 38px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .calendar-date-picker {
    height: 33px;
    background-color: $white;
    border: 1px solid $lightGray;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    cursor: pointer;

    .date-picker-text {
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
    }

    .icon-wrapper {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .react-calendar {
    border-width: 0px;
    border-radius: 4px 4px 4px 4px;
    background: $white;
    box-shadow: 0px 0px 13.4949px 3.37374px rgba(66, 64, 62, 0.12);
    width: 100%;
    height: 316px;

    .react-calendar__viewContainer {
      padding: 0 16px 41px 14px;
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      border-radius: 50%;
      background-color: $gray010;
    }

    .react-calendar__year-view__months {
      display: block !important;
    }

    .react-calendar__month-view__weekdays__weekday {
      padding: 0;
      width: 32px;
      height: 32px;
      text-transform: none;
      color: $gray030;
    }

    .react-calendar__tile.react-calendar__month-view__days__day {
      padding: 0;
      width: 32px;
      height: 32px;
      color: $black;
      margin-bottom: 8px;
    }

    .react-calendar__tile.react-calendar__year-view__months__month {
      padding: 0;
      width: 32px;
      height: 32px;
      color: $black;
      margin: 0px 21px;
      margin-bottom: 8px;
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
      color: $gray030;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
      background-color: transparent;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: white;
    }

    .react-calendar__tile.react-calendar__tile--now {
      border-radius: 50%;
      background-color: $black;
    }
    .react-calendar__month-view__weekdays {
      font-weight: normal;
      color: $gray030;
    }
    abbr {
      text-decoration: none;
    }
    .react-calendar__navigation {
      margin-bottom: 0px;
      button {
        min-width: 30px;
        height: 48px;
      }

      button:enabled:hover {
        background-color: $gray010;
      }

      button:focus {
        background-color: transparent;
      }

      .react-calendar__navigation__label {
        font-size: 14px;
        font-weight: 600;
        pointer-events: none;
      }
    }

    .react-calendar__navigation__prev-button {
      padding: 0;
      justify-content: center;
      align-items: center;
      font-size: xx-large;
      height: 22px;
      font-size: 18px;
    }
    .react-calendar__navigation__prev2-button {
      padding: 0;
      justify-content: center;
      align-items: center;
      font-size: xx-large;
      height: 22px;
      font-size: 18px;
    }
    .react-calendar__navigation__next-button {
      padding: 0;
      justify-content: center;
      align-items: center;
      font-size: xx-large;
      height: 22px;
      font-size: 18px;
    }
    .react-calendar__navigation__next2-button {
      padding: 0;
      justify-content: center;
      align-items: center;
      font-size: xx-large;
      height: 22px;
      font-size: 18px;
    }
    .react-calendar__navigation__label {
      font-size: 16px;
      font-weight: 600;
    }

    .react-calendar__viewContainer {
      padding: 0px;
    }

    .react-calendar__tile:enabled:hover:not(.react-calendar__tile.react-calendar__tile--active) {
      background-color: transparent;

      abbr {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray010 !important;
        margin: auto;
        color: $darkLandline;
      }
    }

    .react-calendar__tile--rangeEnd,
    .react-calendar__tile--rangeEnd:active,
    .react-calendar__tile--rangeEnd:focus,
    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeStart:active,
    .react-calendar__tile--rangeStart:focus {
      abbr {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $darkLandline;
        margin: auto;
        color: $white;
      }
    }

    .react-calendar__tile.react-calendar__tile--now:not(.react-calendar__tile.react-calendar__tile--active) {
      background-color: transparent !important;

      abbr {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray010 !important;
        margin: auto;
        color: $darkLandline;
      }
    }
  }
}
