@import "../../../colors.scss";

.customer-page-container {
  height: 100%;
  overflow-y: auto;

  .fixed-content {
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 2;
    padding: 10px 13.5px 0px 13.5px;
    background-color: $white;
    width: 100%;

    .breaking-line {
      margin-top: 10px;
      border-bottom: 1px solid $gray030;
      width: 100%;
      max-width: 700px;
      margin: 10px auto 0px auto;
    }
  }

  .page-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    text-align: center;
  }

  .scroll-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 13.5px;
    padding-top: 10px;
  }

  .empty-state-image-wrapper {
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
    .empty-state-image {
      width: calc(100% - 21px);
      max-width: 500px;
      height: auto;
      margin: auto 0px;
    }
  }
}
