$primaryLandline: #fab500;
$primaryLandlineHover: #ff9800;
$loyaltyLandline: #ff9800;
$darkLandline: #3c3835;
$white: #ffffff;
$lightGray: #e5e5e5;
$black: #000000;

$gray005: #f5f5f5;
$gray010: #f5f5f5;
$gray030: #cdccc8;
$gray050: #999999;
$gray070: #666666;
$darkGray: #28221e;

$red: #e35c55;
$hoverRed: #cc534d;

$green: #5cbb69;
$hoverGreen: #43a150;
